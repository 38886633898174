import get from 'lodash/get'
import omit from 'lodash/omit'
import forEach from 'lodash/forEach'
import { reactive } from '@vue/composition-api'
import { createFolder } from '@/helper/damUtils'
import { v4 as uuidv4 } from 'uuid'

export default (ctx) => {
  const {
    root: { $store },
  } = ctx

  const initialData = reactive({
    uuid: null,
    id: null,
    status: 'published',
    activity_date: null,
    published_date: null,
    show_homepage: true,
    lang: 'th',
    url: null,
    title: null,
    thumbnail: null,
    folder_name: null,
    created_by: null,
    updated_by: null,
    created_at: null,
    updated_at: null,
  })

  const formData = reactive({
    uuid: null,
    id: null,
    status: 'published',
    activity_date: null,
    published_date: null,
    show_homepage: true,
    lang: 'th',
    url: null,
    title: null,
    thumbnail: null,
    folder_name: null,
    created_by: null,
    updated_by: null,
    created_at: null,
    updated_at: null,
  })

  const prepareFormData = async (result) => {
    const data = omit(result, ['__typename'])
    forEach(formData, (value, key) => {
      if (key === 'show_homepage') {
        formData[key] = data[key] ? 'true' : 'false'
        initialData[key] = data[key] ? 'true' : 'false'
      } else {
        formData[key] = data[key]
        initialData[key] = data[key]
      }
    })
  }

  const fetchByRouteId = async () => {
    const id = get(ctx.root.$router.currentRoute.params, 'id', null)
    const folders = ['Thumbnail']
    if (id) {
      $store
        .dispatch('csr/fetchOne', id)
        .then((result) => prepareFormData(result))
        .then(() => {
          if (!formData.folder_name) {
            const randomUuid = uuidv4()
            formData.folder_name = randomUuid
          }
          return createFolder(`csr/${formData.folder_name}`, folders)
        })
        .catch((error) => {
          console.log(error)
        })
    } else {
      const randomUuid = uuidv4()
      await ctx.root.$vs.loading()
      formData.folder_name = randomUuid
      await createFolder(`csr/${formData.folder_name}`, folders)
      await ctx.root.$vs.loading.close()
    }
  }

  return {
    initialData,
    formData,
    prepareFormData,
    fetchByRouteId,
  }
}
