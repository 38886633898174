<template>
  <div>
    <ValidationObserver v-slot="context">
      <form @submit.prevent="submit(context)">
        <vx-card class="mb-base">
          <FormLayout>
            <FormLabel>
              <span class="required">ชื่อ</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="ชื่อ" 
                rules="required">
                <vs-input 
                  v-model="formData.title" 
                  class="w-full" 
                  name="title" />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span class="required"
              >Thumbnail<br >
                (.jpg , .png ,.gif)</span>
              <br>
              <span class="font-bold">ขนาดรูปจะต้องมีสัดส่วน (Ratio) 16 : 9</span>
              <br>
              <span class="text-warning">ขนาดรูปแนะนำ 1920 x 1080</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                name="Thumbnail"
                rules="required|lh_mimes:jpg,png,gif|lh_ratio:16,9"
              >
                <ImageUpload
                  :folder="`${baseFolder}/csr/${formData.folder_name}/Thumbnail`"
                  v-model="formData.thumbnail"
                  :width="240"
                  :has-delete-button="canDelete(formData.thumbnail)"
                  selection-name="thumbnail"
                />
                <span 
                  v-show="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>URL (External CSR Link)</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="CSR Link"
              >
                <vs-input 
                  v-model="formData.url" 
                  class="w-full" 
                  name="url" />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span class="required">วันที่ทำ CSR</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                name="วันที่ทำ CSR"
                rules="required"
              >
                <datepicker
                  :format="useDatepicker.formatDate"
                  :highlighted="{
                    dates: [
                      // Highlight an array of dates
                      formData.published_date
                        ? new Date(formData.published_date)
                        : new Date(),
                    ],
                  }"
                  v-model="formData.activity_date"
                  placeholder="Select Date"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">{{
                    errors[0]
                  }}</span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span class="required">วันที่จะเผยแพร่</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                name="วันที่จะเผยแพร่"
                rules="required"
              >
                <datepicker
                  :format="useDatepicker.formatDate"
                  :highlighted="{
                    dates: [
                      // Highlight an array of dates
                      formData.published_date
                        ? new Date(formData.published_date)
                        : new Date(),
                    ],
                  }"
                  v-model="formData.published_date"
                  placeholder="Select Date"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">{{
                    errors[0]
                  }}</span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>แสดงบนหน้าเว็บไซต์</span>
            </FormLabel>
            <FormContent>
              <ul>
                <li>
                  <vs-radio
                    v-model="formData.show_homepage"
                    vs-name="show_homepage"
                    vs-value="true"
                  >
                    <span>แสดง</span>
                  </vs-radio>
                </li>
                <li>
                  <vs-radio
                    v-model="formData.show_homepage"
                    vs-name="show_homepage"
                    vs-value="false"
                  >
                    <span>ไม่แสดง</span>
                  </vs-radio>
                </li>
              </ul>
            </FormContent>
          </FormLayout>
        </vx-card>
        <vx-card class="mb-base">
          <FormLayout>
            <FormLabel>
              <span>แก้ไขล่าสุดโดย</span>
            </FormLabel>
            <div class="flex items-center vx-col w-full md:w-6/12">
              <span class="mt-2"> {{formData.updated_by}}</span>
            </div>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>แก้ไขล่าสุดวันที่</span>
            </FormLabel>
            <div class="flex items-center vx-col w-full md:w-6/12">
              <span class="mt-2"> {{formatDate(formData.updated_at)}}</span>
            </div>
          </FormLayout>
          <FormLayout>
            <FormLabel />
            <FormContent class="flex space-x-4">
              <vs-button 
                @click="submit(context)"
              >
                {{ formData.id ? 'Update' : 'Submit' }}
              </vs-button>
              <vs-button 
                v-if="formData.id !== null" 
                @click="preview()"
              >Preview</vs-button>
            </FormContent>
          </FormLayout>
        </vx-card>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { ref, onMounted, watch } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import useCsrUtil from '@/use/useCsrUtil'
import useCrud from '@/use/useCrud'
import useNotify from '@/use/useNotify'
import Wyswyg from '@/components/Wyswyg'
import ImageUpload from '@/components/upload/ImageUpload'
import env from '@/env'
import useDatepicker from '@/use/useDatepicker'
import Datepicker from 'vuejs-datepicker'
import get from 'lodash/get'
import omit from 'lodash/omit'
import isEmpty from 'lodash/isEmpty'

export default {
  name: 'CsrForm',
  components: {
    ValidationObserver,
    Wyswyg,
    ImageUpload,
    Datepicker
  },
  setup(props, ctx) {
    const { success: notifySuccess, error: notifyError, ...notifyFn } = useNotify(ctx)
    const {
      root: { $store, $router, $vs },
    } = ctx
    const {
      formData,
      fetchByRouteId,
    } = useCsrUtil(ctx)
    const baseFolder = ref(env.VUE_APP_CLOUDINARY_DEFAULT_FOLDER)
    const crudForm = useCrud(ctx, 'csr')
    crudForm.routePrefix.value = 'csr'

    onMounted(() => {
      fetchByRouteId()
    })

    const updateItem = ({ validate }) => {
      validate().then((isValid) => {
        if (!isValid) return notifyError({ text: 'กรุณาตรวจสอบข้อมูลให้ถูกต้อง' })
        $vs.loading()

        const activeUser = ctx.root.$store.state.AppActiveUser
        const email = get(activeUser, 'email', '')
        let created_by = formData.created_by
        const updated_by = email

        // add user created by
        if (!created_by) {
          created_by = email
        }

        $store
          .dispatch('csr/updateItem', {
            formData: {
              ...omit(formData, ['updated_at', 'created_at']),
              created_by,
              updated_by
            }
          })
          .then(() => {
            $router.push({ name: 'csr' })
            $vs.loading.close()
            notifySuccess({ text: `${formData.uuid ? 'แก้ไข' : 'เพิ่ม'}ข้อมูลเรียบร้อย` })
          })
          .catch((error) => {
            $vs.loading.close()
            console.log('error: ', error)
            console.log('error', JSON.stringify(error))
            notifyError({ text: JSON.stringify(error) })
          })
      })
    }

    const submit = (ctx) => {
      const existingDb = $store.state.footer.items.find(v => v.url === formData.url)
      if (existingDb && existingDb.id !== formData.id) { 
        return notifyError({
          text: 'ข้อมูลมีอยู่ในระบบ ไม่สามารถเพิ่มข้อมูลได้'
        })
      }

      if (formData.uuid) {
        notifyFn.confirm({
          callback: () => updateItem(ctx) 
        })
      } else {
        crudForm.addItem(ctx, {
          ...formData,
        })
      }
    }

    const preview = () => {
      const lang = $store.state.locale.currentLang
      window.open(`${env.VUE_APP_WEBSITE_URL}/${lang}/csr`)
    }


    const canDelete = (files) => {
      return !isEmpty(files)
    }

    watch(
      () => formData.url,
      (newValue) => {
        if (newValue) {
          const decodeUrl = decodeURIComponent(newValue)
          if (decodeUrl !== formData.url) {
            formData.url = decodeUrl
          }
        }
      }
    )

    return {
      ...crudForm,
      baseFolder,
      updateItem,
      formData,
      submit,
      canDelete,
      useDatepicker: useDatepicker(),
      preview
    }
  },
}
</script>

<style lang="scss">
  .link-manual {
    color: red !important;
  }
  .required:after {
    content: ' *';
    color: red;
  }
</style>
